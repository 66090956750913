<template>
  <AppShell>
    <h1>TEST PAGE</h1>
  </AppShell>
</template>

<script>
import AppShell from '@components/OutsideComponents/AppShell.vue';

export default {
  name: 'TestPage',

  components: {
    AppShell,
  },
};
</script>

<style>
h1 {
  padding: 48px;
}
</style>
